// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookings-js": () => import("./../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-feet-academy-js": () => import("./../src/pages/feet-academy.js" /* webpackChunkName: "component---src-pages-feet-academy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-podiatrists-js": () => import("./../src/pages/our-podiatrists.js" /* webpackChunkName: "component---src-pages-our-podiatrists-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-services-corns-and-calluses-treatment-js": () => import("./../src/pages/services/corns-and-calluses-treatment.js" /* webpackChunkName: "component---src-pages-services-corns-and-calluses-treatment-js" */),
  "component---src-pages-services-diabetic-management-js": () => import("./../src/pages/services/diabetic-management.js" /* webpackChunkName: "component---src-pages-services-diabetic-management-js" */),
  "component---src-pages-services-foot-skin-problems-solved-js": () => import("./../src/pages/services/foot-skin-problems-solved.js" /* webpackChunkName: "component---src-pages-services-foot-skin-problems-solved-js" */),
  "component---src-pages-services-heel-pain-therapy-plantar-fasciitis-js": () => import("./../src/pages/services/heel-pain-therapy-plantar-fasciitis.js" /* webpackChunkName: "component---src-pages-services-heel-pain-therapy-plantar-fasciitis-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-ingrown-toenail-treatment-js": () => import("./../src/pages/services/ingrown-toenail-treatment.js" /* webpackChunkName: "component---src-pages-services-ingrown-toenail-treatment-js" */),
  "component---src-pages-services-orthotics-js": () => import("./../src/pages/services/orthotics.js" /* webpackChunkName: "component---src-pages-services-orthotics-js" */),
  "component---src-pages-services-toenail-troubles-treated-js": () => import("./../src/pages/services/toenail-troubles-treated.js" /* webpackChunkName: "component---src-pages-services-toenail-troubles-treated-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

