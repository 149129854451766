module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mopodiatry","short_name":"Prendiville Ave Podiatry","start_url":"/","background_color":"#618AAC","theme_color":"#618AAC","display":"minimal-ui","icon":"src/images/perth-foot-medic-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"06be104e3167b13eb8b9d276485cd8b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
